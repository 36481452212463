<template>
  <b-card-code>
<!--    <Loader/>-->
    <div v-if="$can('book','Conference Room')" class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="conference_rooms"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template v-if="parseInt(props.row.is_canceled)">
              <span style="color:red">{{ $t('Canceled') }}</span>
            </template>
            <template v-else>
                <!-- modal login button -->
              <b-button v-if="$can('update','Conference Room')" @click="selectedRow(props.row)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-login
                        variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button v-if="$can('cancel','Conference Room')" variant="danger" @click="remove(props.row.id)">
                {{ $t('Cancel') }}
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
             hide-footer size="lg"
             :title="model_mode==='add'?'Add New Information':'Update Information'"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="conference_room_add_or_update">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                >
                  <b-form-input
                      v-model="title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="title here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="c_date"
                      name="c_date"
                      :state="errors.length > 0 ? false:null"
                      placeholder="select a date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="start time"
                    rules="required"
                >
                  <b-form-timepicker
                      v-model="start_time"
                      name="start_time"
                      :state="errors.length > 0 ? false:null"
                      placeholder="start time"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="end time"
                    rules="required"
                >
                  <b-form-timepicker
                      v-model="end_time"
                      name="end_time"
                      :state="errors.length > 0 ? false:null"
                      placeholder="end time"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                >
                  <b-form-textarea
                      v-model="description"
                      name="description"
                      :state="errors.length > 0 ? false:null"
                      placeholder="description write here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="note"
                    rules=""
                >
                  <b-form-textarea
                      v-model="note"
                      name="note"
                      :state="errors.length > 0 ? false:null"
                      placeholder="write note here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>


  </b-card-code>
</template>

<script>
//import Loader from "@/views/Loader";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BForm,BRow, BCol,BFormTextarea,BFormTimepicker,BFormDatepicker
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'ConferenceRoom',
  components: {
    //Loader,
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,//Loader,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv,
    BFormTextarea,BFormTimepicker,BFormDatepicker
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_loader:true,
      title: '',
      c_date: null,
      start_time: null,
      end_time: null,
      description: '',
      note: '',
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      searchTerm: '',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Date',
          field: 'c_date',
        },
        {
          label: 'Start Time',
          field: 'start_time',
        },
        {
          label: 'End Time',
          field: 'end_time',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Note',
          field: 'note',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      columns10: [
        {
          label: 'Title',
          field: 'Title',
        },
        {
          label: 'Date',
          field: 'Date',
        },
        {
          label: 'Start Time',
          field: 'StartTime',
        },
        {
          label: 'End Time',
          field: 'EndTime',
        },
        {
          label: 'Description',
          field: 'Description',
        },
        {
          label: 'Note',
          field: 'Note',
        },
        {
          label: 'Status',
          field: 'Status',
        }
      ],
    }
  },
  methods:{
    selectedRow(row){
      this.selected_row=row;
      this.title=row.title;
      this.c_date=row.c_date;
      this.start_time=row.start_time;
      this.end_time=row.end_time;
      this.description=row.description;
      this.note=row.note;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.conference_room_add_or_update);
          if (this.model_mode ==='add'){
            apiCall.post('/conference/room/information/store',data).then((response)=>{
              if(response.data.status==='success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_CONFERENCE_ROOM');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/conference/room/information/update${this.selected_row.id}`,data).then((response)=>{
              if(response.data.status==='success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_CONFERENCE_ROOM');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    remove(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to cancel this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, cancel it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.put(`/conference/room/information/cancel${id}`).then((response)=>{
              this.$store.dispatch('GET_ALL_CONFERENCE_ROOM');
              this.$swal({
                icon: 'success',
                title: 'Canceled!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Your date is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.title='';
      this.c_date=null;
      this.start_time=null;
      this.end_time=null;
      this.description='';
      this.note='';
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    pdfGenerate() {
      let title = "Conference Room  Bookings";
      let clm = ['Title','Date','StartTime','EndTime','Description','Note','Status'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'conference_room.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['conference_rooms']),
    getData() {
      return this.conference_rooms.map(item => ({
        Title: item.title ? item.title : '',
        Date: item.c_date ? item.c_date : '',
        StartTime: item.start_time ? item.start_time : '',
        EndTime: item.end_time ? item.end_time : '',
        Description: item.description ? item.description : '',
        Note: item.note ? item.note : '',
        Status: item.is_canceled ? 'Booked' : 'Canceled'
      }));
    },
  },
  created() {
    this.$store.dispatch('GET_ALL_CONFERENCE_ROOM');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>